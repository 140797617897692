import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

const VolunteerForm = () => {
  const [step, setStep] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false); // Track submission state
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    age: "",
    gender: "",
    therapySessions: "",
    platformsUsed: "",
    likedMost: "",
    foundMissing: "",
    likedFeatures: "",
    feedback: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = () => {
    setIsSubmitted(true); // Set submission state
    toast.success("Thank you for volunteering with us!");
    // You can handle form submission logic here, such as sending data to the server
  };

  const handleGoHome = () => {
    // Redirect to the home page or perform any other desired action
    window.location.href = "/"; // This will redirect to the home page
  };

  return (
    <div className="container mx-auto p-4 flex items-center justify-center h-screen">
      <Toaster
        position="top-center"
        toastOptions={{ duration: 3000 }}
        reverseOrder={false}
      />
      <div className="max-w-md w-full">
        {isSubmitted ? (
          <div className="text-center">
            <h1
              className="text-3xl font-bold mb-4"
              style={{ color: "#f0703a" }}
            >
              Thank You!
            </h1>
            <p className="mb-4">
              Your feedback has been submitted successfully.
            </p>
            <button
              onClick={handleGoHome}
              className="bg-[#f0703a] text-white px-4 py-2 rounded"
            >
              Back to Home
            </button>
          </div>
        ) : (
          <>
            <h1
              className="text-3xl font-bold text-center mb-4"
              style={{ color: "#f0703a" }}
            >
              Help Us Improve
            </h1>
            <p className="text-center text-gray-600 mb-10 text-lg">
              Volunteer with us in beta testing to help make our platform
              better.
            </p>
            <form>
              {step === 1 && (
                <div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Name</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      className="border rounded p-2 w-full"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="border rounded p-2 w-full"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Phone Number</label>
                    <input
                      type="text"
                      name="number"
                      value={formData.number}
                      onChange={handleInputChange}
                      className="border rounded p-2 w-full"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Age</label>
                    <input
                      type="number"
                      name="age"
                      value={formData.age}
                      onChange={handleInputChange}
                      className="border rounded p-2 w-full"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Gender</label>
                    <select
                      name="gender"
                      value={formData.gender}
                      onChange={handleInputChange}
                      className="border rounded p-2 w-full"
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <button
                    type="button"
                    onClick={handleNextStep}
                    className="bg-[#f0703a] text-white px-4 py-2 rounded mt-4"
                  >
                    Next
                  </button>
                </div>
              )}

              {step === 2 && (
                <div>
                  <div className="mb-4">
                    <label className="block text-gray-700">
                      Have you taken therapy sessions before?
                    </label>
                    <input
                      type="text"
                      name="therapySessions"
                      value={formData.therapySessions}
                      onChange={handleInputChange}
                      className="border rounded p-2 w-full"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">
                      Which Platforms have you used ?
                    </label>
                    <input
                      type="text"
                      name="platformsUsed"
                      value={formData.platformsUsed}
                      onChange={handleInputChange}
                      className="border rounded p-2 w-full"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">
                      What did you like the most about them?
                    </label>
                    <input
                      type="text"
                      name="likedMost"
                      value={formData.likedMost}
                      onChange={handleInputChange}
                      className="border rounded p-2 w-full"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">
                      What did you find missing?
                    </label>
                    <input
                      type="text"
                      name="foundMissing"
                      value={formData.foundMissing}
                      onChange={handleInputChange}
                      className="border rounded p-2 w-full"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={handlePreviousStep}
                    className="bg-gray-300 px-4 py-2 rounded mr-2"
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    onClick={handleNextStep}
                    className="bg-[#f0703a] text-white px-4 py-2 rounded"
                  >
                    Next
                  </button>
                </div>
              )}

              {step === 3 && (
                <div>
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">
                      Which feature of ours are you most excited about?
                    </label>
                    <select
                      name="likedFeatures"
                      value={formData.likedFeatures}
                      onChange={handleInputChange}
                      className="border rounded p-2 w-full"
                    >
                      <option value="">Select a feature</option>
                      <option value="Chatbot">
                        24/7 Personalized Chatbot Support
                      </option>
                      <option value="Sleep and Fitness Tracking">
                        Sleep and Fitness Tracking
                      </option>
                      <option value="Nutrition Tracking">
                        Nutrition Tracking
                      </option>
                      <option value="Community">Community</option>
                      <option value="Personalized Reports">
                        Personalized Report of Therapy
                      </option>
                    </select>
                  </div>

                  <div className="mb-4">
                    <label className="block text-gray-700">
                      We would love to hear a feedback from you.
                    </label>
                    <input
                      type="text"
                      name="feedback"
                      value={formData.feedback}
                      onChange={handleInputChange}
                      className="border rounded p-2 w-full"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={handlePreviousStep}
                    className="bg-gray-300 px-4 py-2 rounded mr-2"
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="bg-[#f0703a] text-white px-4 py-2 rounded"
                  >
                    Submit
                  </button>
                </div>
              )}
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default VolunteerForm;
