import expertDiagnosis from "../../assets/diagnosis_1.png";
import personalisedTherapy from "../../assets/personalisedTherapy.png";
import holisticSolution from "../../assets/HolisticSolution.png";

const WhyUs = () => {
  return (
    <section className="bg-[#373f61] py-8 md:py-14 lg:py-16 text-white">
      <div className="container lg:mx-auto text-center padding-x">
        {/* Section Title */}
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4">
          Why Choose Us?
        </h2>
        <p className="text-base md:text-lg text-gray-400 mb-6">
          Explore the Key Benefits of Our Approach
        </p>

        {/* Flexbox Content */}
        <div className="flex flex-col lg:flex-row justify-evenly items-center gap-y-8 lg:gap-y-0 lg:gap-x-8">
          {/* Expert Diagnosis */}
          <div className="flex flex-col items-center text-white py-4 lg:p-3 xl:p-5 max-w-xs md:max-w-full">
            <img
              src={expertDiagnosis}
              alt="Expert Diagnosis"
              className="mb-4 w-10 sm:w-14 md:w-20"
            />
            <h3 className="text-lg md:text-xl font-semibold mb-3">
              Expert Diagnosis
            </h3>
            <p className="text-base text-gray-400 leading-relaxed md:leading-snug md:max-w-full">
              Leverage our advanced diagnostic tools to gain a clear
              understanding of your mental health needs.
            </p>
          </div>

          {/* Personalized Therapy */}
          <div className="flex flex-col items-center text-white py-4 lg:p-3 xl:p-5 max-w-xs md:max-w-full">
            <img
              src={personalisedTherapy}
              alt="Personalized Therapy"
              className="mb-4 w-10 sm:w-14 md:w-20"
            />
            <h3 className="text-lg md:text-xl font-semibold mb-3">
              Personalized Therapy
            </h3>
            <p className="text-base text-gray-400 leading-relaxed md:leading-snug md:max-w-full">
              Get matched with experienced therapists who provide personalized
              care based on your unique needs.
            </p>
          </div>

          {/* Holistic Solutions */}
          <div className="flex flex-col items-center text-white py-4 lg:p-3 xl:p-5 max-w-xs md:max-w-full">
            <img
              src={holisticSolution}
              alt="Holistic Solutions"
              className="mb-4 w-10 sm:w-14 md:w-20"
            />
            <h3 className="text-lg md:text-xl font-semibold mb-3">
              Holistic Solutions
            </h3>
            <p className="text-base text-gray-400 leading-relaxed md:leading-snug md:max-w-full">
              Access a variety of therapies and tools designed to support your
              mental well-being from multiple angles.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
