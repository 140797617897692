import React, { useState } from "react";

const anxietyTestQuestions = [
  "I find it very hard to unwind, relax or sit still",
  "I have had stomach problems, such as feeling sick or stomach cramps",
  "I have been irritable and easily become annoyed",
  "I have experienced shortness of breath",
  "I have felt dizzy and unsteady at times",
  "I have had difficulties with sleep (including waking early, finding it hard to go to sleep)",
  "I have felt panicked and overwhelmed by things in my life",
  "I have felt nervous and on edge",
  "I have had trembling hands",
  "I seem to be constantly worrying about things",
];

const anxietyOptions = ["Never", "Rarely", "Sometimes", "Often", "Very Often"];

function AnxietyTest() {
  const [answers, setAnswers] = useState(
    Array(anxietyTestQuestions.length).fill(null)
  );
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleAnswerChange = (questionIndex, optionIndex) => {
    const newAnswers = [...answers];
    newAnswers[questionIndex] = optionIndex;
    setAnswers(newAnswers);

    // Move to the next question after an option is selected
    if (currentQuestionIndex + 1 < anxietyTestQuestions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleSubmit();
    }
  };

  const calculateAnxietyRisk = () => {
    const totalScore = answers.reduce((sum, answer) => sum + (answer ?? 0), 0);
    let resultMessage = "";
    if (totalScore >= 35) {
      resultMessage =
        "High anxiety level. You may want to seek professional help.";
    } else if (totalScore >= 20) {
      resultMessage =
        "Moderate anxiety level. Consider talking to someone about your feelings.";
    } else {
      resultMessage =
        "Low anxiety level. However, if you have concerns, do not hesitate to reach out for support.";
    }
    return resultMessage;
  };

  const handleSubmit = () => {
    const anxietyAssessment = calculateAnxietyRisk();
    setResult(anxietyAssessment);
    setSubmitted(true);
  };

  return (
    <div className="min-h-screen bg-white flex items-center justify-center py-36 px-12">
      <div className="bg-slate-100 shadow-lg p-12 rounded-lg max-w-5xl w-full">
        <h1 className="text-3xl font-bold mb-16 text-center text-[#f0703a] hover:scale-105 transform transition-all">
          Anxiety Test
        </h1>

        {/* Progress based on completed questions */}
        <div className="w-full bg-gray-300 rounded-full h-3 mb-8">
          <div
            className="bg-[#f0703a] h-3 rounded-full transition-all"
            style={{
              width: `${
                (answers.filter((a) => a !== null).length /
                  anxietyTestQuestions.length) *
                100
              }%`,
            }}
          ></div>
        </div>
        <p className="text-center text-gray-600 mb-6">
          Question {currentQuestionIndex + 1} of {anxietyTestQuestions.length}
        </p>

        {!submitted ? (
          <>
            <div className="mb-10">
              <h2 className="font-semibold text-lg mb-6 text-gray-800">
                {anxietyTestQuestions[currentQuestionIndex]}
              </h2>
              <div className="flex space-x-6">
                {anxietyOptions.map((option, optionIndex) => (
                  <div
                    key={optionIndex}
                    onClick={() =>
                      handleAnswerChange(currentQuestionIndex, optionIndex)
                    }
                    className={`cursor-pointer p-4 w-full max-w-xs text-center rounded-lg transition-all border-2 mb-6 ${
                      answers[currentQuestionIndex] === optionIndex
                        ? "bg-[#f0703a] text-white"
                        : "bg-white text-gray-900 hover:bg-[#f0703a] hover:text-white"
                    }`}
                  >
                    {option}
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className="text-center">
            <h2 className="text-xl font-semibold mb-4">Your Results:</h2>
            <p className="text-lg">{result}</p>
            <button
              onClick={() => {
                setSubmitted(false);
                setCurrentQuestionIndex(0);
                setAnswers(Array(anxietyTestQuestions.length).fill(null));
              }}
              className="mt-4 bg-[#f0703a] text-white py-2 px-6 rounded-lg hover:bg-[#cc6235] transition-all"
            >
              Retake Test
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default AnxietyTest;
