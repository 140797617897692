import React, { useState } from "react";

const bulimiaTestQuestions = [
  "Do you have a regular daily eating pattern?",
  "Would you say you are a strict dieter?",
  "If you break your diet, do you feel like a failure?",
  "Do you count calories all the time, regardless of being on a diet?",
  "Do you ever fast for a whole day as a way of controlling your weight?",
  "Does your eating pattern severely disrupt your life?",
  "Would you say food, or thoughts of food, dominates your life?",
  "Do you ever eat so much that you become uncomfortably full?",
  "Would you say you can control your eating?",
  "Do you worry that you have very little control over what you eat?",
  "Do you ever experience overpowering urges to binge eat?",
  "Does the thought of becoming fat terrify you?",
  "When you feel anxious, do you tend to eat?",
  "Do you turn to food as a comfort, for instance when feeling sad or anxious?",
  "Do you ever eat large amounts of food, very quickly, outside of meal times?",
  "Do you ever binge on large amounts of food?",
  "Are you ashamed of your eating habits?",
  "Are you able to leave food on your plate, if you are full?",
  "Do you ever lie about how much you have eaten?",
  "Does the amount you eat depend on how hungry you are?",
  "Do you feel miserable following binges?",
  "Do you ever feel extreme guilt following a period of overeating?",
  "Do you ever eat in secret?",
  "Do you eat ‘normally’ in front of others, but then binge on your own?",
];

const options = ["Yes", "No"];

function BulimiaTest() {
  const [answers, setAnswers] = useState(
    Array(bulimiaTestQuestions.length).fill(null)
  );
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleAnswerChange = (questionIndex, optionIndex) => {
    const newAnswers = [...answers];
    newAnswers[questionIndex] = optionIndex;
    setAnswers(newAnswers);

    if (currentQuestionIndex + 1 < bulimiaTestQuestions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleSubmit();
    }
  };

  const calculateScore = () => {
    const yesAnswers = answers.filter((answer) => answer === 0).length;
    let resultMessage = "";
    if (yesAnswers >= 15)
      resultMessage = "You may be at high risk for bulimia.";
    else if (yesAnswers >= 7)
      resultMessage = "You may have a moderate risk for bulimia.";
    else resultMessage = "Low risk for bulimia.";

    return resultMessage;
  };

  const handleSubmit = () => {
    const riskAssessment = calculateScore();
    setResult(riskAssessment);
    setSubmitted(true);
  };

  return (
    <div className="min-h-screen bg-white flex items-center justify-center py-36 px-12">
      <div className="bg-slate-100 shadow-lg p-12 rounded-lg max-w-5xl w-full">
        <h1 className="text-3xl font-bold mb-16 text-center text-[#f0703a] hover:scale-105 transform transition-all">
          Are You At Risk for Bulimia?
        </h1>

        {/* Progress based on completed questions */}
        <div className="w-full bg-gray-300 rounded-full h-3 mb-8">
          <div
            className="bg-[#f0703a] h-3 rounded-full transition-all"
            style={{
              width: `${
                (answers.filter((a) => a !== null).length /
                  bulimiaTestQuestions.length) *
                100
              }%`,
            }}
          ></div>
        </div>
        <p className="text-center text-gray-600 mb-6">
          Question {currentQuestionIndex + 1} of {bulimiaTestQuestions.length}
        </p>

        {!submitted ? (
          <>
            <div className="mb-10">
              <h2 className="font-semibold text-lg mb-6 text-gray-800">
                {bulimiaTestQuestions[currentQuestionIndex]}
              </h2>
              <div className="flex space-x-6">
                {options.map((option, optionIndex) => (
                  <div
                    key={optionIndex}
                    onClick={() =>
                      handleAnswerChange(currentQuestionIndex, optionIndex)
                    }
                    className={`cursor-pointer p-4 w-full max-w-xs text-center rounded-lg transition-all border-2 mb-6 ${
                      answers[currentQuestionIndex] === optionIndex
                        ? "bg-[#f0703a] text-white"
                        : "bg-white text-gray-900 hover:bg-[#f0703a] hover:text-white"
                    }`}
                  >
                    {option}
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className="text-center">
            <h2 className="text-xl font-semibold mb-4">Your Results:</h2>
            <p className="text-lg">{result}</p>
            <button
              onClick={() => {
                setSubmitted(false);
                setCurrentQuestionIndex(0);
                setAnswers(Array(bulimiaTestQuestions.length).fill(null));
              }}
              className="mt-4 bg-[#f0703a] text-white py-2 px-6 rounded-lg hover:bg-[#cc6235] transition-all"
            >
              Retake Test
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default BulimiaTest;
