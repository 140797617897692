import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const Login = () => {
  const [loginInput, setLoginInput] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const loginData = {
      username: loginInput,
      password,
    };

    try {
      const response = await fetch("https://api.rezen.in/api/v1/login/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });

      const result = await response.json();

      if (response.ok && result.success) {
        toast.success(result.msg || "Login successful");
        // Redirect to the home page or a different page after login
        navigate("/"); // Update this path as needed
      } else {
        toast.error(result.msg || "Login failed");
      }
    } catch (error) {
      toast.error("An error occurred while logging in");
    }
  };

  return (
    <div className="bg-custom-white min-h-screen flex items-center justify-center px-6 py-24">
      <div className="max-w-lg w-full bg-blue-50 px-8 py-6 rounded-lg shadow-md">
        <h1 className="text-3xl font-bold text-gray-900 mb-8 text-center">
          Welcome Back
        </h1>
        <form className="space-y-6" onSubmit={handleLogin}>
          <div>
            <label
              className="block text-gray-700 font-medium mb-1"
              htmlFor="loginInput"
            >
              Username or Email
            </label>
            <input
              value={loginInput}
              onChange={(e) => setLoginInput(e.target.value)}
              type="text"
              id="loginInput"
              name="loginInput"
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
              placeholder="Enter your username or email"
              required
            />
          </div>

          <div>
            <label
              className="block text-gray-700 font-medium mb-1"
              htmlFor="password"
            >
              Password
            </label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              id="password"
              name="password"
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
              placeholder="Enter your password"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full bg-[#ff6f56] text-white py-3 rounded-lg font-semibold shadow-md hover:bg-[#ea5b42] transition duration-300"
          >
            Login
          </button>

          <p className="text-center text-gray-600 mt-4">
            Don’t have an account?{" "}
            <Link
              to="/signup"
              className="text-lg text-[#ff6f56] font-medium hover:underline"
            >
              Sign up here
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Login;
