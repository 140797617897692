import React from "react";

function ContactUs() {
  return (
    <section className="w-full bg-[#fdf1db] py-16 px-6 md:px-20">
      {/* Contact Us Title */}
      <h2 className="text-3xl md:text-4xl font-bold text-center text-[#373f61] mb-16">
        Contact Us
      </h2>

      {/* Contact Information Section */}
      <div className="max-w-5xl flex mx-auto flex-col md:flex-row justify-between items-start space-y-8 md:space-y-0 md:space-x-12 lg:space-x-32">
        {/* Left Side: Contact Details + Map */}
        <div className="w-full md:w-1/2 space-y-6">
          <h3 className="text-2xl font-semibold text-[#373f61]">
            Get in Touch
          </h3>
          <p className="text-base text-gray-600">
            We’re here to help. Reach out to us through any of the options
            below, and we’ll get back to you as soon as possible.
          </p>
          <div className="space-y-4">
            <div className="flex items-center space-x-3">
              <span className="text-[#373f61] font-semibold">Location:</span>
              <span className="text-gray-600">Sg Palya, Bengaluru, 560029</span>
            </div>
            <div className="flex items-center space-x-3">
              <span className="text-[#373f61] font-semibold">Email:</span>
              <a
                href="mailto:contact@rezen.in"
                className="text-[#f0703a] hover:text-[#cc6235] transition-all"
              >
                contact@rezen.in
              </a>
            </div>
            <div className="flex items-center space-x-3">
              <span className="text-[#373f61] font-semibold">Phone:</span>
              <a
                href="tel:+918409412228"
                className="text-[#f0703a] hover:text-[#cc6235] transition-all"
              >
                8409412228
              </a>
            </div>
          </div>

          {/* Google Map Embed */}
          <div className="w-full mt-6">
            <iframe
              title="Our Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.743762650051!2d77.599059574157!3d12.944241440911558!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15972438f3d7%3A0x61f7d421f9d840d7!2sS.G.%20Palya%2C%20Bengaluru%2C%20Karnataka%20560029!5e0!3m2!1sen!2sin!4v1695806841637!5m2!1sen!2sin"
              width="100%"
              height="200"
              className="border-0 rounded-lg"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        {/* Right Side: Contact Form */}
        <div className="w-full md:w-1/2 bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-2xl font-semibold text-[#373f61] mb-6">
            Send Us a Message
          </h3>
          <form>
            <div className="mb-4">
              <label className="block text-gray-600 mb-2">Your Name</label>
              <input
                type="text"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#f0703a]"
                placeholder="Enter your name"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-600 mb-2">Email Address</label>
              <input
                type="email"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#f0703a]"
                placeholder="Enter your email"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-600 mb-2">Message</label>
              <textarea
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#f0703a]"
                placeholder="Write your message"
                rows="5"
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-[#f0703a] text-white font-semibold py-3 rounded-md hover:bg-[#cc6235] transition-all"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
