import HeroBg from "../../assets/bg-illustration.png";
import { motion } from "framer-motion";
import { FadeRight } from "../../utility/animation";
import toast from "react-hot-toast";
import "../../index.css";

function Hero() {
  const notifyDownload = () =>
    toast.custom((t) => (
      <div
        className={`${
          t.visible ? "animate-enter" : "animate-leave"
        } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      >
        <div className="flex-1 w-0 p-4">
          <div className="flex items-start">
            <div className="ml-3 flex-1">
              <p className="text-sm font-medium text-gray-900">
                App Launching Soon!
              </p>
              <p className="mt-1 text-sm text-gray-500">
                Stay tuned for the official app launch, coming to your favorite
                app stores soon.
              </p>
            </div>
          </div>
        </div>
        <div className="flex border-l border-gray-200">
          <button
            onClick={() => toast.dismiss(t.id)}
            className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-darker hover:text-darkest focus:outline-none focus:ring-2 focus:ring-darker"
          >
            Close
          </button>
        </div>
      </div>
    ));

  // Function to scroll to the BookFreeSession section
  const scrollToBookSession = () => {
    const section = document.getElementById("free-session");
    section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section
      style={{
        backgroundImage: `url(${HeroBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="min-h-[90vh] bg-no-repeat bg-cover flex items-center"
    >
      <div className="flex flex-col w-full mb-10 gap-y-8 lg:gap-x-16 items-start justify-center padding-x">
        {/* Left Side */}
        <div className="flex flex-col justify-center py-8 lg:py-0">
          {/* Content spacing controlled by padding-x */}
          <div className="space-y-4 sm:space-y-5 md:space-y-6 lg:max-w-[600px] md:max-w-[550px] sm:max-w-[460px]">
            <motion.h1
              variants={FadeRight(0.6)}
              initial="hidden"
              animate="visible"
              className="text-3xl md:text-4xl lg:text-5xl font-bold leading-normal font-roboto text-[#2F2E41] text-left"
            >
              Preserving Your Happiness
            </motion.h1>
            <motion.h2
              variants={FadeRight(0.9)}
              initial="hidden"
              animate="visible"
              className="text-xl md:text-2xl lg:text-3xl text-darker tracking-wide text-left"
            >
              Keep Your Mind and Soul Healthy
            </motion.h2>
            <motion.p
              variants={FadeRight(1.2)}
              initial="hidden"
              animate="visible"
              className="text-gray-700 text-base text-left"
            >
              Discover new ways to nurture your mental and emotional well-being.
              We are here to help you embrace positivity, achieve inner peace,
              and sustain your happiness.
            </motion.p>
            <motion.div
              variants={FadeRight(1.5)}
              initial="hidden"
              animate="visible"
              className="flex flex-col min-[480px]:flex-row gap-4 md:gap-8 py-2 sm:py-0"
            >
              <button
                onClick={scrollToBookSession} // Scroll to BookFreeSession section
                className="primaryBtn text-sm sm:text-base"
              >
                Book Free Session
              </button>

              <button
                onClick={notifyDownload} // Trigger 'Coming Soon' notification for download
                className="primaryBtn text-sm sm:text-base"
              >
                Download App
              </button>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
