import React from "react";

const teamMembers = [
  { name: "Saurav Kumar", email: "krsaurav2208@gmail.com" },
  { name: "Yash Tripathi", email: "tripathiyash1004@gmail.com" },
  { name: "Ishaan Dwivedi", email: "ishaan.dwivedi99@gmail.com" },
  { name: "Gaurav Kumar", email: "gaurv1407@gmail.com" },
];

const TeamPage = () => {
  return (
    <div className="bg-[#fdf1db] min-h-screen flex items-center justify-center font-sora">
      <div className="max-w-4xl mx-auto px-4">
        <h2 className="text-4xl font-semibold text-center text-[#333] mb-8">
          Meet the Team
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
            >
              <h3 className="text-2xl font-medium text-[#ff6600] mb-2">
                {member.name}
              </h3>
              <p className="text-lg text-[#666]">{member.email}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamPage;
