import React from "react";
import { Link } from "react-router-dom";

const Blogs = () => {
  return (
    <>
      {/* Blogs */}
      <div className="bg-[#fdf1db] py-24 px-6">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-700 mb-12">
            Our Latest Blog Posts
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-center">
            {/* Blog Post 1 */}
            <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col max-w-sm mx-auto">
              <img
                src="https://images.pexels.com/photos/3184305/pexels-photo-3184305.jpeg"
                alt="Tobacco Addiction: Understanding and Overcoming"
                className="w-full h-40 object-cover"
              />
              <div className="flex-1 p-4">
                <h3 className="text-2xl font-bold text-gray-600 mb-3">
                  Tobacco Addiction: Understanding and Overcoming
                </h3>
                <p className="text-gray-700 mb-3">
                  Learn about the causes, effects, and strategies to overcome
                  tobacco addiction in a healthy and sustainable way.
                </p>
              </div>
              <div className="pb-5">
                <Link to="/tobacco-addiction">
                  <button className="inline-block bg-[#f0703a] text-white font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-[#cc6235] transition duration-300">
                    Read More
                  </button>
                </Link>
              </div>
            </div>

            {/* Blog Post 2 */}
            <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col max-w-sm mx-auto">
              <img
                src="https://images.pexels.com/photos/935977/pexels-photo-935977.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Counseling vs Psychotherapy: What’s the Difference?"
                className="w-full h-40 object-cover"
              />
              <div className="flex-1 p-4">
                <h3 className="text-2xl font-bold text-gray-600 mb-3">
                  Counseling vs Psychotherapy: What’s the Difference?
                </h3>
                <p className="text-gray-700 mb-3">
                  Explore the differences between counseling and psychotherapy,
                  and find out which one may be best for your mental health.
                </p>
              </div>
              <div className="pb-5">
                <Link to="/counseling-vs-psychotherapy">
                  <button className="inline-block bg-[#f0703a] text-white font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-[#cc6235] transition duration-300">
                    Read More
                  </button>
                </Link>
              </div>
            </div>

            {/* Blog Post 3 */}
            <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col max-w-sm mx-auto">
              <img
                src="https://images.pexels.com/photos/935777/pexels-photo-935777.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Sleep in Recovery: The Key to Healing"
                className="w-full h-40 object-cover"
              />
              <div className="flex-1 p-4">
                <h3 className="text-2xl font-bold text-gray-600 mb-3">
                  Sleep in Recovery: The Key to Healing
                </h3>
                <p className="text-gray-700 mb-3">
                  Sleep is crucial for mental recovery. Learn about its
                  importance and how it aids in the healing process.
                </p>
              </div>
              <div className="pb-5">
                <Link to="/sleep-in-recovery">
                  <button className="inline-block bg-[#f0703a] text-white font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-[#cc6235] transition duration-300">
                    Read More
                  </button>
                </Link>
              </div>
            </div>

            {/* Blog Post 4 */}
            <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col max-w-sm mx-auto">
              <img
                src="https://images.pexels.com/photos/4050332/pexels-photo-4050332.jpeg"
                alt="Medication for OCD: What to Know"
                className="w-full h-40 object-cover"
              />
              <div className="flex-1 p-4">
                <h3 className="text-2xl font-bold text-gray-600 mb-3">
                  Medication for OCD: What to Know
                </h3>
                <p className="text-gray-700 mb-3">
                  A comprehensive guide on medications available for treating
                  OCD and how they work.
                </p>
              </div>
              <div className="pb-5">
                <Link to="/medication-for-ocd">
                  <button className="inline-block bg-[#f0703a] text-white font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-[#cc6235] transition duration-300">
                    Read More
                  </button>
                </Link>
              </div>
            </div>

            {/* Blog Post 5 */}
            <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col max-w-sm mx-auto">
              <img
                src="https://images.pexels.com/photos/4101144/pexels-photo-4101144.jpeg"
                alt="When Depression Worsens: Signs and Steps"
                className="w-full h-40 object-cover"
              />
              <div className="flex-1 p-4">
                <h3 className="text-2xl font-bold text-gray-600 mb-3">
                  When Depression Worsens: Signs and Steps
                </h3>
                <p className="text-gray-700 mb-3">
                  Recognize the signs of worsening depression and understand the
                  steps you can take to get the help you need.
                </p>
              </div>
              <div className="pb-5">
                <Link to="/depression-worsening">
                  <button className="inline-block bg-[#f0703a] text-white font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-[#cc6235] transition duration-300">
                    Read More
                  </button>
                </Link>
              </div>
            </div>

            {/* Blog Post 6 */}
            <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col max-w-sm mx-auto">
              <img
                src="https://images.pexels.com/photos/2128817/pexels-photo-2128817.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="Anxiety Disorders: How to Cope"
                className="w-full h-40 object-cover"
              />
              <div className="flex-1 p-4">
                <h3 className="text-2xl font-bold text-gray-600 mb-3">
                  Anxiety Disorders: How to Cope
                </h3>
                <p className="text-gray-700 mb-3">
                  Learn about different anxiety disorders, their symptoms, and
                  strategies to manage anxiety effectively.
                </p>
              </div>
              <div className="pb-5">
                <Link to="/anxiety-disorders">
                  <button className="inline-block bg-[#f0703a] text-white font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-[#cc6235] transition duration-300">
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
