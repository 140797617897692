import React from "react";
import { Link } from "react-router-dom";

const AssessmentPage = () => {
  return (
    <div className="bg-[#fdf1db] min-h-screen py-16 pb-28">
      <div className="max-w-4xl mx-auto px-4 lg:px-8">
        <h1 className="text-3xl lg:text-4xl font-semibold text-center mb-12 mt-8 text-[#f0703a]">
          Choose an Assessment
        </h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          <Link to="/depression-test">
            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform duration-300 text-center hover:shadow-xl transform hover:scale-105 min-h-[180px]">
              <h2 className="text-2xl font-semibold mb-4 text-[#f0703a]">
                Depression Test
              </h2>
              <p>Take a test to check for symptoms of depression.</p>
            </div>
          </Link>
          <Link to="/anxiety-test">
            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform duration-300 text-center hover:shadow-xl transform hover:scale-105 min-h-[180px]">
              <h2 className="text-2xl font-semibold mb-4 text-[#f0703a]">
                Anxiety Test
              </h2>
              <p>Take a test to check for symptoms of anxiety.</p>
            </div>
          </Link>
          <Link to="/anorexia-test">
            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform duration-300 text-center hover:shadow-xl transform hover:scale-105 min-h-[180px]">
              <h2 className="text-2xl font-semibold mb-4 text-[#f0703a]">
                Anorexia Test
              </h2>
              <p>Take a test to check for symptoms of anorexia.</p>
            </div>
          </Link>
          <Link to="/attitude-test">
            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform duration-300 text-center hover:shadow-xl transform hover:scale-105 min-h-[180px]">
              <h2 className="text-2xl font-semibold mb-4 text-[#f0703a]">
                Attitude Test
              </h2>
              <p>Take a test to check what kind of attitude you have.</p>
            </div>
          </Link>
          <Link to="/emotional-intelligence-test">
            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform duration-300 text-center hover:shadow-xl transform hover:scale-105 min-h-[180px]">
              <h2 className="text-2xl font-semibold mb-4 text-[#f0703a]">
                Emotional Intelligence Test
              </h2>
              <p>Take a test to check emotional intelligence.</p>
            </div>
          </Link>
          <Link to="/bulimia-test">
            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform duration-300 text-center hover:shadow-xl transform hover:scale-105 min-h-[180px]">
              <h2 className="text-2xl font-semibold mb-4 text-[#f0703a]">
                Bulimia Test
              </h2>
              <p>Take a test to check for symptoms of bulimia.</p>
            </div>
          </Link>
          <Link to="/ocd-test">
            <div className="bg-white p-6 rounded-lg shadow-lg transition-transform duration-300 text-center hover:shadow-xl transform hover:scale-105 min-h-[180px]">
              <h2 className="text-2xl font-semibold mb-4 text-[#f0703a]">
                OCD Test
              </h2>
              <p>
                Take a test to check for symptoms of obsessive-compulsive
                disorder.
              </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AssessmentPage;
