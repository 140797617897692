import React, { useState } from "react";

const depressionTestQuestions = [
  "I feel overwhelmingly sad at times",
  "When I think of the future I feel hopeless",
  "I feel like a complete failure",
  "I get a lot of satisfaction / joy from doing things",
  "I feel guilty about something most of the time",
  "I feel like I am being punished",
  "I feel disappointed (even disgusted) with myself",
  "The bad things in my life aren’t all my fault",
  "I am often on the brink of tears or cry",
  "I feel irritated and annoyed by things in my life",
  "I am very interested in other people’s lives and like to listen to them",
  "I find it easy to make decisions, big and small",
  "I think I am unattractive or ugly",
  "I find it really hard to do anything, especially work",
  "My sleep patterns have been really disrupted",
  "I am so tired I don’t have the energy to do anything",
  "My appetite has changed a lot",
  "I have lost a lot of weight",
  "I am very concerned, even preoccupied, with my physical health",
  "I am not interested in sexual relations at all",
  "I have thought about ending my life",
];

const options = [
  "Definitely agree",
  "Slightly agree",
  "Slightly disagree",
  "Definitely disagree",
];

function DepressionTest() {
  const [answers, setAnswers] = useState(
    Array(depressionTestQuestions.length).fill(null)
  );
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleAnswerChange = (questionIndex, optionIndex) => {
    const newAnswers = [...answers];
    newAnswers[questionIndex] = optionIndex;
    setAnswers(newAnswers);

    // Move to the next question after an option is selected
    if (currentQuestionIndex + 1 < depressionTestQuestions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleSubmit();
    }
  };

  const calculateDepressionScore = () => {
    return answers.reduce((score, answer) => {
      return score + (answer !== null ? 3 - answer : 0);
    }, 0);
  };

  const handleSubmit = () => {
    const score = calculateDepressionScore();
    let resultMessage = "";
    if (score <= 21) resultMessage = "Low likelihood of depression.";
    else if (score <= 42) resultMessage = "You may have mild depression.";
    else if (score <= 63) resultMessage = "You may have moderate depression.";
    else resultMessage = "You may have severe depression.";

    setResult(resultMessage);
    setSubmitted(true);
  };

  return (
    <div className="min-h-screen bg-white flex items-center justify-center py-36 px-12">
      <div className="bg-slate-100 shadow-lg p-12 rounded-lg max-w-5xl w-full">
        <h1 className="text-3xl font-bold mb-16 text-center text-[#f0703a] hover:scale-105 transform transition-all">
          Are You Depressed?
        </h1>

        {/* Progress based on completed questions */}
        <div className="w-full bg-gray-300 rounded-full h-3 mb-8">
          <div
            className="bg-[#f0703a] h-3 rounded-full transition-all"
            style={{
              width: `${
                (answers.filter((a) => a !== null).length /
                  depressionTestQuestions.length) *
                100
              }%`,
            }}
          ></div>
        </div>
        <p className="text-center text-gray-600 mb-6">
          Question {currentQuestionIndex + 1} of{" "}
          {depressionTestQuestions.length}
        </p>

        {!submitted ? (
          <>
            <div className="mb-10">
              <h2 className="font-semibold text-lg mb-6 text-gray-800">
                {depressionTestQuestions[currentQuestionIndex]}
              </h2>
              <div className="flex space-x-6">
                {options.map((option, optionIndex) => (
                  <div
                    key={optionIndex}
                    onClick={() =>
                      handleAnswerChange(currentQuestionIndex, optionIndex)
                    }
                    className={`cursor-pointer p-4 w-full max-w-xs text-center rounded-lg transition-all border-2 mb-6 ${
                      answers[currentQuestionIndex] === optionIndex
                        ? "bg-[#f0703a] text-white"
                        : "bg-white text-gray-900 hover:bg-[#f0703a] hover:text-white"
                    }`}
                  >
                    {option}
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className="text-center">
            <h2 className="text-xl font-semibold mb-4">Your Results:</h2>
            <p className="text-lg">{result}</p>
            <button
              onClick={() => {
                setSubmitted(false);
                setCurrentQuestionIndex(0);
                setAnswers(Array(depressionTestQuestions.length).fill(null));
              }}
              className="mt-4 bg-[#f0703a] text-white py-2 px-6 rounded-lg hover:bg-[#cc6235] transition-all"
            >
              Retake Test
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default DepressionTest;
