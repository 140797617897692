import React from "react";
import toast from "react-hot-toast";
const HandleButton = () => {
  toast.custom((t) => (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-start">
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-gray-900">
              Feature Coming Soon!
            </p>
            <p className="mt-1 text-sm text-gray-500">
              Stay tuned for updates!
            </p>
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-200">
        <button
          onClick={() => toast.dismiss(t.id)}
          className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-darker hover:text-darkest focus:outline-none focus:ring-2 focus:ring-darker"
        >
          Close
        </button>
      </div>
    </div>
  ));
};

const MythFactSection = () => {
  return (
    <div className="bg-gray-100 py-32 md:px-20 px-12">
      <h2 className="text-center text-xl md:text-2xl lg:text-3xl font-bold mb-14 text-gray-700">
        What you should know about women’s mental health...
      </h2>
      <div className="max-w-6xl mx-auto grid gap-y-12 gap-x-20 lg:grid-cols-2">
        {/* Myth-Fact Box 1 */}
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <h3 className="text-red-500 text-lg font-bold flex items-center mb-4">
            <span className="mr-2">✖</span> Myth
          </h3>
          <p className="mb-4">
            Hormonal changes alone cause all mental health issues in women.
          </p>
          <h3 className="text-green-500 text-lg font-bold flex items-center mb-4">
            <span className="mr-2">✔</span> Fact
          </h3>
          <p>
            While hormonal fluctuations (e.g., during menstrual cycles,
            pregnancy, or menopause) can impact mental health, factors such as
            genetics, environment, and lifestyle also play significant roles.
            Mental health conditions require comprehensive care beyond just
            hormone regulation.
          </p>
        </div>

        {/* Myth-Fact Box 2 */}
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <h3 className="text-red-500 text-lg font-bold flex items-center mb-4">
            <span className="mr-2">✖</span> Myth
          </h3>
          <p className="mb-4">
            Women are more emotional and therefore more likely to exaggerate
            their mental health struggles.
          </p>
          <h3 className="text-green-500 text-lg font-bold flex items-center mb-4">
            <span className="mr-2">✔</span> Fact
          </h3>
          <p>
            Mental health issues are real and should not be dismissed as
            exaggerated emotions. Both men and women can experience significant
            mental health challenges that require proper attention and
            treatment, regardless of gender stereotypes.
          </p>
        </div>
      </div>

      {/* Explore Care Options Button */}
      <div className="flex justify-center mt-16">
        <button
          onClick={HandleButton}
          className="bg-[#f0703a] text-white px-5 py-3 md:text-base text-sm font-semibold rounded-md hover:bg-[#cc6235] transition-all duration-300"
        >
          Explore Care Options
        </button>
      </div>
    </div>
  );
};

export default MythFactSection;
