import React, { useState } from "react";

const emotionalIntelligenceTestQuestions = [
  "I am aware of my emotions as I experience them.",
  "I know how my emotions influence my decisions.",
  "I understand why I behave the way I do in different situations.",
  "I can easily recognize the emotions of others.",
  "I am good at managing my stress.",
  "I can regulate my emotions when I need to.",
  "I handle difficult situations with calm and composure.",
  "I am comfortable talking about my emotions.",
  "I empathize with others’ feelings.",
  "I stay positive even in challenging situations.",
  "I find it easy to understand the emotions of those around me.",
];

const options = [
  "Definitely agree",
  "Slightly agree",
  "Slightly disagree",
  "Definitely disagree",
];

function EmotionalIntelligenceTest() {
  const [answers, setAnswers] = useState(
    Array(emotionalIntelligenceTestQuestions.length).fill(null)
  );
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleAnswerChange = (questionIndex, optionIndex) => {
    const newAnswers = [...answers];
    newAnswers[questionIndex] = optionIndex;
    setAnswers(newAnswers);

    if (currentQuestionIndex + 1 < emotionalIntelligenceTestQuestions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleSubmit();
    }
  };

  const calculateScore = () => {
    return answers.reduce((score, answer) => {
      return score + (answer !== null ? 3 - answer : 0);
    }, 0);
  };

  const handleSubmit = () => {
    const score = calculateScore();
    let resultMessage = "";
    if (score <= 11) resultMessage = "Low emotional intelligence.";
    else if (score <= 22) resultMessage = "Moderate emotional intelligence.";
    else resultMessage = "High emotional intelligence.";

    setResult(resultMessage);
    setSubmitted(true);
  };

  return (
    <div className="min-h-screen bg-white flex items-center justify-center py-36 px-12">
      <div className="bg-slate-100 shadow-lg p-12 rounded-lg max-w-5xl w-full">
        <h1 className="text-3xl font-bold mb-16 text-center text-[#f0703a] hover:scale-105 transform transition-all">
          Emotional Intelligence Test
        </h1>

        {/* Progress based on completed questions */}
        <div className="w-full bg-gray-300 rounded-full h-3 mb-8">
          <div
            className="bg-[#f0703a] h-3 rounded-full transition-all"
            style={{
              width: `${
                (answers.filter((a) => a !== null).length /
                  emotionalIntelligenceTestQuestions.length) *
                100
              }%`,
            }}
          ></div>
        </div>
        <p className="text-center text-gray-600 mb-6">
          Question {currentQuestionIndex + 1} of{" "}
          {emotionalIntelligenceTestQuestions.length}
        </p>

        {!submitted ? (
          <>
            <div className="mb-10">
              <h2 className="font-semibold text-lg mb-6 text-gray-800">
                {emotionalIntelligenceTestQuestions[currentQuestionIndex]}
              </h2>
              <div className="flex space-x-6">
                {options.map((option, optionIndex) => (
                  <div
                    key={optionIndex}
                    onClick={() =>
                      handleAnswerChange(currentQuestionIndex, optionIndex)
                    }
                    className={`cursor-pointer p-4 w-full max-w-xs text-center rounded-lg transition-all border-2 mb-6 ${
                      answers[currentQuestionIndex] === optionIndex
                        ? "bg-[#f0703a] text-white"
                        : "bg-white text-gray-900 hover:bg-[#f0703a] hover:text-white"
                    }`}
                  >
                    {option}
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className="text-center">
            <h2 className="text-xl font-semibold mb-4">Your Results:</h2>
            <p className="text-lg">{result}</p>
            <button
              onClick={() => {
                setSubmitted(false);
                setCurrentQuestionIndex(0);
                setAnswers(
                  Array(emotionalIntelligenceTestQuestions.length).fill(null)
                );
              }}
              className="mt-4 bg-[#f0703a] text-white py-2 px-6 rounded-lg hover:bg-[#cc6235] transition-all"
            >
              Retake Test
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default EmotionalIntelligenceTest;
