import { useState } from "react";

const BookSession = () => {
  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [problem, setProblem] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [errors, setErrors] = useState({});

  const validateStep1 = () => {
    const newErrors = {};
    if (!name) newErrors.name = "Name is required";
    if (!email || !/\S+@\S+\.\S+/.test(email))
      newErrors.email = "Valid email is required";
    if (!phone || !/^\d{10}$/.test(phone))
      newErrors.phone = "Valid phone number is required";
    if (!age || age <= 0) newErrors.age = "Valid age is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextStep = (e) => {
    e.preventDefault();
    if (validateStep1()) {
      setStep(2);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!gender || !problem || !date || !time) {
      alert("Please fill in all fields");
    } else {
      // Submit form logic
      console.log({
        name,
        email,
        phone,
        age,
        gender,
        problem,
        date,
        time,
      });
    }
  };

  const minDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const timeOptions = [
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
    "4:00 PM",
    "5:00 PM",
    "6:00 PM",
    "7:00 PM",
    "8:00 PM",
    "9:00 PM",
  ];

  return (
    <div className="bg-custom-white min-h-screen flex items-center justify-center px-6 py-24">
      <div className="max-w-lg w-full bg-blue-50 px-8 py-6 rounded-lg shadow-md">
        {/* Progress Indicator */}
        <h2 className="text-lg font-semibold text-gray-700 mb-4 text-center">
          Step {step} of 2
        </h2>

        <h1 className="text-3xl font-bold text-gray-900 mb-8 text-center">
          Book Your Free Session
        </h1>

        <form className="space-y-6" onSubmit={handleSubmit}>
          {step === 1 && (
            <>
              {/* Name Input */}
              <div>
                <label
                  className="block text-gray-700 font-medium mb-1"
                  htmlFor="name"
                >
                  Full Name
                </label>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  id="name"
                  name="name"
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                  placeholder="John Doe"
                  required
                />
                {errors.name && (
                  <p className="text-red-500 text-sm">{errors.name}</p>
                )}
              </div>

              {/* Email Input */}
              <div>
                <label
                  className="block text-gray-700 font-medium mb-1"
                  htmlFor="email"
                >
                  Email Address
                </label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  id="email"
                  name="email"
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                  placeholder="you@example.com"
                  required
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>

              {/* Phone Input */}
              <div>
                <label
                  className="block text-gray-700 font-medium mb-1"
                  htmlFor="phone"
                >
                  Phone Number
                </label>
                <input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type="tel"
                  id="phone"
                  name="phone"
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                  placeholder="1234567890"
                  required
                />
                {errors.phone && (
                  <p className="text-red-500 text-sm">{errors.phone}</p>
                )}
              </div>

              {/* Age Input */}
              <div>
                <label
                  className="block text-gray-700 font-medium mb-1"
                  htmlFor="age"
                >
                  Age
                </label>
                <input
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  type="number"
                  id="age"
                  name="age"
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                  placeholder="Your age"
                  required
                />
                {errors.age && (
                  <p className="text-red-500 text-sm">{errors.age}</p>
                )}
              </div>

              {/* Next Button */}
              <button
                type="button"
                onClick={handleNextStep}
                className="w-full bg-[#ff6f56] text-white py-3 rounded-lg font-semibold shadow-md hover:bg-[#ea5b42] transition duration-300"
              >
                Next
              </button>
            </>
          )}

          {step === 2 && (
            <>
              {/* Gender Dropdown */}
              <div>
                <label
                  className="block text-gray-700 font-medium mb-1"
                  htmlFor="gender"
                >
                  Gender
                </label>
                <select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  id="gender"
                  name="gender"
                  className="cursor-pointer w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                  required
                >
                  <option
                    value=""
                    className="cursor-pointer"
                    disabled
                    hidden
                  ></option>
                  <option value="male" className="cursor-pointer">
                    Male
                  </option>
                  <option value="female" className="cursor-pointer">
                    Female
                  </option>
                  <option value="others" className="cursor-pointer">
                    Others
                  </option>
                  <option value="prefer-not-to-say" className="cursor-pointer">
                    Prefer not to say
                  </option>
                </select>
              </div>

              {/* Problem Dropdown */}
              <div>
                <label
                  className="block text-gray-700 font-medium mb-1"
                  htmlFor="problem"
                >
                  Problem Facing
                </label>
                <select
                  value={problem}
                  onChange={(e) => setProblem(e.target.value)}
                  id="problem"
                  name="problem"
                  className="cursor-pointer w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                  required
                >
                  <option
                    value=""
                    disabled
                    hidden
                    className="cursor-pointer"
                  ></option>
                  <option value="depression" className="cursor-pointer">
                    Depression
                  </option>
                  <option value="anxiety" className="cursor-pointer">
                    Anxiety
                  </option>
                  <option value="ocd" className="cursor-pointer">
                    OCD
                  </option>
                  <option value="women-issues" className="cursor-pointer">
                    Women Issues
                  </option>
                  <option value="adhd" className="cursor-pointer">
                    ADHD
                  </option>
                  <option value="others" className="cursor-pointer">
                    Others
                  </option>
                  <option value="dont-know" className="cursor-pointer">
                    Don't Know
                  </option>
                </select>
              </div>

              {/* Date Picker */}
              <div>
                <label
                  className="block text-gray-700 font-medium mb-1"
                  htmlFor="date"
                >
                  Preferred Date
                </label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                  required
                  min={minDate()}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>

              {/* Time Dropdown */}
              <div>
                <label
                  className="block text-gray-700 font-medium mb-1"
                  htmlFor="time"
                >
                  Preferred Time
                </label>
                <select
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  id="time"
                  name="time"
                  className="cursor-pointer w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                  required
                >
                  <option
                    value=""
                    disabled
                    hidden
                    className="cursor-pointer"
                  ></option>
                  {timeOptions.map((timeOption, index) => (
                    <option
                      key={index}
                      value={timeOption}
                      className="cursor-pointer"
                    >
                      {timeOption}
                    </option>
                  ))}
                </select>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="w-full bg-[#ff6f56] text-white py-3 rounded-lg font-semibold shadow-md hover:bg-[#ea5b42] transition duration-300"
              >
                Submit
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default BookSession;
