import React, { useState } from "react";

const attitudeTestQuestions = [
  "When times get tough, I remember that I have people to rely on.",
  "I believe I have a good chance of achieving all my life goals.",
  "When something bad happens, I tend to blame myself first.",
  "If someone compliments me, I think they don’t mean it.",
  "When I meet someone new, my first instinct is to -",
  "I seek the opinion and approval of others before doing anything.",
  "I view criticism as opportunities to get better and prove myself.",
  "I don’t expect good feedback about my work or proposals.",
  "If one thing goes wrong, I assume everything else will too.",
  "When my boss calls me into his office, I expect to get -",
  "I think I won’t lose anything by helping people in need.",
  "When I think about the future I tend to start panicking.",
  "I aim for the stars thinking I may land on the moon at least.",
  "I believe people have good intentions when they do something.",
];

const attitudeOptions = [
  "Definitely agree",
  "Slightly agree",
  "Slightly disagree",
  "Definitely disagree",
];

function AttitudeTest() {
  const [answers, setAnswers] = useState(
    Array(attitudeTestQuestions.length).fill(null)
  );
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleAnswerChange = (questionIndex, optionIndex) => {
    const newAnswers = [...answers];
    newAnswers[questionIndex] = optionIndex;
    setAnswers(newAnswers);

    // Move to the next question after an option is selected
    if (currentQuestionIndex + 1 < attitudeTestQuestions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleSubmit();
    }
  };

  const calculateAttitudeScore = () => {
    return answers.reduce((score, answer) => {
      return score + (answer !== null ? 3 - answer : 0);
    }, 0);
  };

  const handleSubmit = () => {
    const score = calculateAttitudeScore();
    let resultMessage = "";
    if (score <= 21) resultMessage = "Positive attitude.";
    else if (score <= 42) resultMessage = "Neutral attitude.";
    else resultMessage = "Negative attitude.";

    setResult(resultMessage);
    setSubmitted(true);
  };

  return (
    <div className="min-h-screen bg-white flex items-center justify-center py-36 px-12">
      <div className="bg-slate-100 shadow-lg p-12 rounded-lg max-w-5xl w-full">
        <h1 className="text-3xl font-bold mb-16 text-center text-[#f0703a] hover:scale-105 transform transition-all">
          Attitude Test
        </h1>

        {/* Progress based on completed questions */}
        <div className="w-full bg-gray-300 rounded-full h-3 mb-8">
          <div
            className="bg-[#f0703a] h-3 rounded-full transition-all"
            style={{
              width: `${
                (answers.filter((a) => a !== null).length /
                  attitudeTestQuestions.length) *
                100
              }%`,
            }}
          ></div>
        </div>
        <p className="text-center text-gray-600 mb-6">
          Question {currentQuestionIndex + 1} of {attitudeTestQuestions.length}
        </p>

        {!submitted ? (
          <>
            <div className="mb-10">
              <h2 className="font-semibold text-lg mb-6 text-gray-800">
                {attitudeTestQuestions[currentQuestionIndex]}
              </h2>
              <div className="flex space-x-6">
                {attitudeOptions.map((option, optionIndex) => (
                  <div
                    key={optionIndex}
                    onClick={() =>
                      handleAnswerChange(currentQuestionIndex, optionIndex)
                    }
                    className={`cursor-pointer p-4 w-full max-w-xs text-center rounded-lg transition-all border-2 mb-6 ${
                      answers[currentQuestionIndex] === optionIndex
                        ? "bg-[#f0703a] text-white"
                        : "bg-white text-gray-900 hover:bg-[#f0703a] hover:text-white"
                    }`}
                  >
                    {option}
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className="text-center">
            <h2 className="text-xl font-semibold mb-4">Your Results:</h2>
            <p className="text-lg">{result}</p>
            <button
              onClick={() => {
                setSubmitted(false);
                setCurrentQuestionIndex(0);
                setAnswers(Array(attitudeTestQuestions.length).fill(null));
              }}
              className="mt-4 bg-[#f0703a] text-white py-2 px-6 rounded-lg hover:bg-[#cc6235] transition-all"
            >
              Retake Test
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default AttitudeTest;
