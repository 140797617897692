import React from "react";

function TermsConditions() {
  return (
    <div className="flex flex-col items-center justify-start py-16 px-6 sm:px-8 bg-gray-50 min-h-screen">
      <div className="max-w-4xl w-full bg-white rounded-lg shadow-md p-8">
        <h1 className="text-3xl font-bold text-gray-800 mb-4">
          Terms & Conditions
        </h1>
      </div>
    </div>
  );
}

export default TermsConditions;
